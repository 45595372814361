import {useContext } from 'react';
import Container from 'react-bootstrap/Container';
import classes from './FinalPage.module.css'
import logo from '../../assets/logo-thankyou.png'
import {useNavigate} from 'react-router-dom';
import { userName } from '../../util/context';
import { Button } from 'react-bootstrap';
import axios from "axios";



function FinalPage() {
 
    const navigate = useNavigate();

    const onGoHomeClick=()=>{
        navigate('/');

    }
   
    return(
        
            <div className={classes.container}>
                <div className={classes.logo}>
                    <img src={logo}/>

                </div>

                <div className={classes.titleContainer}>
                    <h1>Thank you for choosing us</h1>
                </div>
                <div className={classes.subTitleContainer}>
                    <h1>We wish you a good game</h1>
                </div>

                {/* <div className={classes.title}>
                <h1>
                    Payment Details
                </h1>
            </div> */}
            
         

               
                <div className={classes.linkToHome} onClick={onGoHomeClick}>
                    <p>Go Home</p>
                </div>                 
                 

            </div>
            
        
    )
}

export default FinalPage;