import { useState,useEffect,useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Offcanvas from 'react-bootstrap/Offcanvas';
import classes from './RequestForm.module.css'
import PaymentDetails from '../../components/PaymentDetails/PaymentDetails';
import { Outlet,useNavigate,Form as RouterForm, redirect,useLoaderData} from "react-router-dom";
import {getContact} from '../../util/api'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { userName } from '../../util/context';
import axios from "axios";

function RequestForm(props) {
 

  const navigate = useNavigate();
  const gameFormatData = props.gameFormat;

  const [show, setShow] = useState(false);
  const [gameDate, setGameDate] = useState('');
  const [starTime, setStartTime] = useState('');
  const [starTimeValue, setStartTimeValue] = useState('');
  const [finisTime, setFinisTime] = useState('');
  const [isToday, setIsToday] = useState(false);
  const [timeIsValid, setTimeIsValid] = useState(true);
  const [isSlotAvailable, setIsSlotAvailable] = useState(true);
  const [selectedGround, setSelectedGround] = useState();
  const [endTimeIsGreater, setEndTimeIsGreater] = useState(true);
  


  const {showSideBar,setShowSideBar,setIsSideBarOpen,setSelectedFormat,setConfirmationDetails,grounds,setSelectedGroundContext}=useContext(userName)

  useEffect(() => {
    console.log('inside use effect')
    setFinisTime('');
    setStartTime('')
    setStartTimeValue('')
    setSelectedFormat(gameFormatData.format)
   
  
},[gameFormatData.hours])

  const handleClose = () => {
    setIsSideBarOpen(false)
    setShowSideBar(false)
  };
  const handleShow = () => {
    // axios.post(slotCheck+'/checkOverlap',null,
    // {
        
    //     params: {
    //         Ground_Name: JSON.parse(localStorage.getItem('location')),
    //         Slot:starTime+' - '+finisTime,
    //         Date:gameDate,

    //     }
    //   },)
    // .then((res) => {
    //   if(res.data.message=='No overlap'){
    //     setIsSideBarOpen(true)
    //     setShowSideBar(true)
    //     setIsSlotAvailable(true)

    //   }
    //   if(res.data.message=='overlap'){
    //     setIsSlotAvailable(false)
    //   }

    // })
    setConfirmationDetails({
      gameDate:gameDate,
      startTime:starTime,
      finishTime:finisTime,
      hours:gameFormatData.hours,
      price:gameFormatData.price * gameFormatData.hours,
      ground:selectedGround

    })
    

    setIsSideBarOpen(true)
     setShowSideBar(true)
   
  }
  const onDateSelect=(e)=>{
    setGameDate(e.target.value)
    let selectedDate=new Date(e.target.value)
    let todaysDate = new Date();
    if(selectedDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
    setIsToday(true)   
    }
    else{
      setIsToday(false)
    }
  }
  const onTimeSelect=(e)=>{
    setStartTimeValue(e.target.value)
    let startTimeHrs=e.target.value.split(':');
    let seconds1 = (startTimeHrs[0]*60+parseInt( startTimeHrs[1]))*60
    let date = e.target.value.split(':');
    let startTimeHours=parseInt(date[0])
    let startTimeMinutes=date[1]
    setStartTime(ampm(startTimeHours,startTimeMinutes))

    let hours = parseInt(date[0])+gameFormatData.hours;
    hours=hours<=24?hours:hours%24
    let minutes = date[1];
     setFinisTime(ampm(hours, minutes))
     checkRange(seconds1)
   

  
  }
  const checkRange=(seconds)=>{
    let todaysDate=new Date()
    let todaysHours=todaysDate.getHours()
    let todaysMinutes=todaysDate.getMinutes()
    
    if((seconds/60)<(todaysHours*60+todaysMinutes+15)){
      console.log('inside range check '+(todaysHours*60+todaysMinutes))
      setTimeIsValid(false)
    }
    else{
      setTimeIsValid(true)
    }
    if((seconds/60)+gameFormatData.hours*60>=24*60){
      
      setEndTimeIsGreater(false)
    }
    else{
      setEndTimeIsGreater(true)
    }


  }
  function ampm(hours, minutes) {
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    minutes = minutes || 0;
    // minutes = minutes < 10 ? '0'+minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
}
// useEffect(() => {
//   console.log('inside use effect')
//   setStartTime('')
// }, [gameFormatData.hours]);
const onChangeGround=(e)=>{
  console.log(e.target.value)
  setSelectedGround(e.target.value)
  setSelectedGroundContext(e.target.value)

}
    return (
      <Form >
      <div className={classes.formRow}>
        {(isToday&&!timeIsValid)&&<p className={classes.validation}>choose a slot time atlease 15 minutes ahead</p>}
      </div> 
      <div className={classes.formRow}>
        {(!endTimeIsGreater)&&<p className={classes.validation}>End time must be greater than start time</p>}
      </div> 
      <div className={classes.formRow}>
        <span className={classes.label}>Hours</span> 
        <div className={classes.rightBox}>
          <span className={classes.label}>{gameFormatData.hours}</span>
        </div>
      </div>
      <div className={classes.formRow}>
        <span className={classes.label}>Ground</span> 
        <div className={classes.rightBox}>
        <select onChange={onChangeGround} value={selectedGround}>
        <option >Select Ground</option>
          {grounds.map(ground=>{
            return( <option key={ground.Ground_Id} value={ground.Ground_Name}>{ground.Ground_Name}</option>)
          })}
         </select>
        </div>
      </div>
      <div className={classes.formRow}>
        <span className={classes.label}>Date</span> 
        <div className={classes.rightBox}>
          <Form.Control type="date"   onChange={onDateSelect} min={new Date().toISOString().split('T')[0]}/>
        </div>
      </div>
      <div className={classes.formRow}>
        <span className={classes.label}>Start At</span> 
        <div className={classes.rightBox}>
          <Form.Control type='time' onChange={onTimeSelect} value={starTimeValue}/>
        </div>
      </div>
      <div className={classes.formRow}>
        <span className={classes.label}>Finish At</span> 
        <div className={classes.rightBox}>
          <span className={classes.label}>{finisTime}</span>
        </div>
      </div>
      <div className={classes.formRow}>
        <span className={classes.price}>${gameFormatData.price * gameFormatData.hours}</span> 
        <div className={classes.rightBox}>
          <Button variant="light" disabled={!endTimeIsGreater||!selectedGround||!gameDate||!starTime||isToday&&!timeIsValid}  onClick={handleShow}>Request Live Streaming</Button>
        </div>
      </div>
     
      <Offcanvas placement='end' show={showSideBar} onHide={handleClose}>
        
        <PaymentDetails gameDate={gameDate} startTime={starTime} finishTime={finisTime} hours={gameFormatData.hours} price={gameFormatData.price * gameFormatData.hours}/>
        {/* <Outlet /> */}
      </Offcanvas>

      </Form>
    );
  }
  
 export default RequestForm;
  // export async function loader({ params }) {
  //   console.log('param '+params.formatId)
  //   return getContact(params.formatId);
  // }