import React,{useState,useContext} from 'react';
import classes from './PhoneNumberInput.module.css'
import {useNavigate} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import 'react-phone-number-input/style.css'
import PhoneInput,{ isValidPhoneNumber } from 'react-phone-number-input'
import { userName } from '../../../util/context';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';







function PhoneNumberInput() {
   const slotUrl = process.env.REACT_APP_URL_KIOSK;
   const {setGameFormats,setGrounds,setPracticePrice}=useContext(userName)
    const [phoneNumber,setPhoneNumber]=useState('')
    const [isLoading,setIsLoading]=useState(false)
    const navigate = useNavigate();
    const onClickStart=()=>{
        setIsLoading(true)

        localStorage.setItem('number', JSON.stringify(phoneNumber));
        

        axios
        .post(slotUrl+'/signup',null,
        {
            params: {
                phoneNumber: phoneNumber,
                parkName:JSON.parse(localStorage.getItem('location'))
            }
          },)
        .then((res) => {
           console.log(res)
           if(res.data.User_Id){
            localStorage.setItem('userid', JSON.stringify(res.data.User_Id));
            console.log('userid '+res.data.User_Id)
            console.log('game format '+res.data.gameFormatData)
            setGameFormats(res.data.gameFormatData)
            setGrounds(res.data.groundData)
            setPracticePrice(parseInt(res.data.PracticeAmountPerHour))
            // localStorage.setItem("gameFormats", JSON.stringify(res.data.gameFormatData));
            navigate('/home')
            setIsLoading(false)
           }

        })

    }
    // const onPhoneNumberChange=(e)=>{
    //     setPhoneNumber(e.target.value)
    //     let phoneNo = /^\d{10}$/;
    //     if(e.target.value.match(phoneNo))
    //           {
    //             setIsPhoneNumberValid(true)
    //           }
    //         else
    //           {
    //             setIsPhoneNumberValid(false)
    //           }
    //     console.log('number '+localStorage.getItem('number'))

    // }

    return(
        
            <div>
                
                <div className={classes.label}>
                    <h1>Enter your phone number to get started</h1>
                </div>
                <div className={classes.phoneNumber}>
                    {/* <Form.Control style={{fontSize:'30px'}} type='text' onChange={onPhoneNumberChange} className='inputField' /> */}
                    <PhoneInput
                       placeholder="Enter phone number"
                       defaultCountry="US"
                       value={phoneNumber}
                       onChange={setPhoneNumber}
                       style={{fontSize:'30px'}}
                       />
                       
                </div>
                <div className={classes.buttonWrapper}>
                <Button variant="light" disabled={phoneNumber?!isValidPhoneNumber(phoneNumber):'ture'}   onClick={onClickStart}>Get Started</Button>
                </div>
                <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </div>
            
        
    )
}

export default PhoneNumberInput;