import Container from 'react-bootstrap/Container';
import styles from './Configure.module.css'
import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import logo from '../../assets/logo-initial.png'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import userPool from '../../userPool';
import { userName } from '../../util/context';
import axios from "axios";
import { TextField, Button, Box, Typography, InputLabel, Select, FormControl, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DottedLines from '../../components/DottedLines/DottedLines';


function Configure() {
  const slotUrl = process.env.REACT_APP_URL_KIOSK;
  const { selectedPark } = useContext(userName)
  const { channelIds, adminUserName, repData } = useContext(userName)


  const navigate = useNavigate();
  const [kioskName, setKioskName] = useState('')
  const [channelId, setChannelId] = useState(channelIds.length > 0 ? channelIds[0].id : '')
  const [modalShow, setModalShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)


  // const onClickSetLocation=()=>{
  //     localStorage.setItem('location', JSON.stringify(location));
  //     navigate('/');
  // }

  const onChangeKioskName = (e) => {
    setKioskName(e.target.value)
  }
  const onChangeChannelId = (e) => {
    setChannelId(e.target.value)
  }

  const getParks = (idToken) => {
    axios
      .get(slotUrl + '/admin/getDetails',
        {
          params: {
            idToken: idToken,
          }
        },)
      .then((res) => {
        console.log(res)
        if (res.data) {
          // setAllParks(res.data.Results)
          navigate('/parklist');

        }

      })

  }

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser()
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject()
          }
          else {
            resolve(session)
          }
        })
      } else {
        reject()
      }
    })

  }

  const onSubmit = () => {
    setIsLoading(true)
    axios
      .post(slotUrl + '/admin/setup', null,
        {
          params: {
            Park_Id: selectedPark.Park_Id,
            Park_Name: selectedPark.Park_Name,
            Channel_Id: channelId,
            City_Id: selectedPark.City_Id,
            kioskName: kioskName,
            username: adminUserName,
            repData: JSON.stringify(repData)

          }
        },)
      .then((res) => {
        console.log(res)
        if (res.data) {
          // setAllParks(res.data.Results)
          localStorage.setItem('location', JSON.stringify(selectedPark.Park_Name))
          localStorage.setItem('Kiosk_Id', JSON.stringify(res.data.Kiosk_Id))
          setIsLoading(false)
          setModalShow(true)


        }

      })

  }
  const onFinish = () => {
    const user = userPool.getCurrentUser()
    if (user) {
      user.signOut()
    }

    navigate('/');
  }
  const onGoHome = () => {
    navigate('/')
  }

  

  return (
    <>



      <div className={styles.mycomponent}>

        <div className={styles.centerbox}>
          <div className={styles.leftBox}>
            {channelIds.length > 0 && <div className={styles.logocontainer}>
              <img
                className={styles.logo}
                src={logo}
                alt="Logo"
              />
              <p className={styles.title}>FIELDS MANAGER</p>
            </div>}
          </div>
          <div className={styles.rightbox}>
            {channelIds.length > 0 && <div className={styles.formcontainer}>
              <h2 className={styles.rightboxtitle}>{selectedPark.Park_Name}</h2>


              <div className={styles.right_box__form}>


                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="select-label">Channel Id</InputLabel>
                  <Select
                    className={styles._input}
                    value={channelId}
                    labelId="select-label"
                    id="select"
                    onChange={e => {
                      console.log("e.target.value", e.target.value);
                      setChannelId(e.target.value);
                    }}
                    label="Channel Id"
                  >
                    {channelIds.map((data, i) => {
                      return <MenuItem style={{ fontSize: '15px' }} key={i} value={data.id}>{data.name}</MenuItem>
                    })}

                  </Select>
                  <TextField
                    label="Kiosk Name"
                    onChange={(e) => onChangeKioskName(e)}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className={styles._input}
                  />
                </FormControl>

              </div>
              <div className={styles.buttonWrapper}>

                <Button className={styles.submit} variant="contained" onClick={onSubmit}>
                  Submit
                </Button>
              </div>

            </div>}

            {channelIds.length == 0 &&
              <div className={styles.nochannel}>

                <img
                  className={styles.logo}
                  src={logo}
                  alt="Logo"
                />
                <p className={styles.titlenochannel}>FIELDS MANAGER</p>

                <div className={styles.info}>
                  <p>No active channels, please contact +19729728393</p>
                </div>

                <Button className={styles.submit} onClick={onGoHome} variant="contained" >
                  Go Home
                </Button>


              </div>}

          </div>
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

      </div>

      <Dialog
        open={modalShow}
        onClose={onFinish}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ fontSize: '22px' }}>
              This kiosk is configured successfully
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <Button style={{ backgroundColor: '#0E4626', color: '#ffffff' }} onClick={onFinish}>Finish</Button>
          </DialogActions>
        </div>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>


    </>
  )
}

export default Configure;