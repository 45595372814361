import React,{useState} from 'react';
import './App.css';
import Home from "./pages/Home/Home";
import ErrorPage from "./pages/EroorPage/ErrorPage"
import Game from './pages/Game/Game'
import Practice,{loader as PracticeLoader} from './pages/Practice/Practice'
import RequestForm,{loader as RequestFormLoder} from './pages/RequestForm/RequestForm'
import ThankYou from './pages/ThankYou/ThankYou'
import InitialPage from "./pages/InitialPage/InitialPage";
import SetLocation from './pages/SetLocation/SetLocation';
import FinalPage from './pages/FinalPage/FinalPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import "./index.css";
import {userName} from './util/context'
import SignIn from './pages/SignIn/SignIn';
import ParkList from './pages/ParkList/ParkList';
import Configure from './pages/Configure/Configure';
import ConfigurationDetails from './pages/ConfigurationDetails/ConfigurationDetails';

const router = createBrowserRouter([
  {
    path: "/",
    element: <InitialPage/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/location",
    element: <SetLocation/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <Home/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/game",
    element: <Game/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "request/:formatId",
        element: <RequestForm />,
        // loader: RequestFormLoder,
      },
    ]
  },
  {
    path: "/practice",
    element: <Practice/>,
    errorElement: <ErrorPage />,
    // loader:PracticeLoader
  },
  {
    path: "/thankyou",
    element: <ThankYou/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/final",
    element: <FinalPage/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/SignIn",
    element: <SignIn/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/parklist",
    element: <ParkList/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/configure",
    element: <Configure/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/configurationdetails",
    element: <ConfigurationDetails/>,
    errorElement: <ErrorPage />,
  },
]);


function App() {
  const [showSideBar,setShowSideBar]=useState(false)
  const [isSideBarOpen,setIsSideBarOpen]=useState(false)
  const [onPay,setOnPay]=useState(false)
  const [selectedFormat,setSelectedFormat]=useState('')
  const [stopChecking,setStopChecking]=useState(false)
  const [confirmationDetails,setConfirmationDetails]=useState('')
  const [gameFormats,setGameFormats]=useState('')
  const [grounds,setGrounds]=useState('')
  const [serviceRequestId,setServiceRequestId]=useState('')
  const [slotId,setSlotId]=useState('')
  const [selectedGround,setSelectedGroundContext]=useState('')
  const [allParks,setAllParks]=useState([])
  const [channelIds,setchannelIds]=useState([])
  const [selectedPark,setSelectedPark]=useState({})
  const [cityId,setCityId]=useState({})
  const [sportsList,setSportsList]=useState([])
  const [adminUserName,setAdminUserName]=useState({})
  const [repData,setRepData]=useState({})
  const [configData,setConfigData]=useState({})
  const [paymentDialogOpen,setPaymentDialogOpen]=useState(false)
  const [isPaymentDialogOpen,setIsPaymentDialogOpen]=useState(false)
  const [qrImage,setQrImage]=useState('')
  const [practicePrice,setPracticePrice]=useState('')
  const [ remainingTime, setRemainingTime]=useState('')




  
















  return (
    <userName.Provider 
    value={{showSideBar,setShowSideBar,isSideBarOpen,
            setIsSideBarOpen,onPay,
            setOnPay,selectedFormat,setSelectedFormat,
            stopChecking,setStopChecking,
            confirmationDetails,setConfirmationDetails,
            gameFormats,setGameFormats,
            grounds,setGrounds,
            serviceRequestId,setServiceRequestId,
            slotId,setSlotId,
            selectedGround,setSelectedGroundContext,
            allParks,setAllParks,
            channelIds,setchannelIds,
            selectedPark,setSelectedPark,
            cityId,setCityId,
            sportsList,setSportsList,
            adminUserName,setAdminUserName,
            repData,setRepData,
            configData,setConfigData,
            paymentDialogOpen,setPaymentDialogOpen,
            isPaymentDialogOpen,setIsPaymentDialogOpen,
            qrImage,setQrImage,
            practicePrice,setPracticePrice,
            remainingTime, setRemainingTime
            }}>
    <RouterProvider router={router} />
    </userName.Provider>
  );
}

export default App;
