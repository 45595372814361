import Container from 'react-bootstrap/Container';
import classes from './InitialPage.module.css'
import logo from '../../assets/logo-initial.png'
import settings from '../../assets/settings.png'

import {useNavigate} from 'react-router-dom';
import PhoneNumberInput from '../../components/PaymentDetails/PhoneNumberInput/PhoneNumberInput';
import { useState,useEffect,useContext} from 'react';
import { userName } from '../../util/context';



function InitialPage() {
  const {setShowSideBar,setIsSideBarOpen,setOnPay,setPaymentDialogOpen,setIsPaymentDialogOpen,setQrImage}=useContext(userName)
   const [startAnimation,setStartAnimation]=useState(false)
   const [touchStart, setTouchStart] = useState(null)
   const [touchEnd, setTouchEnd] = useState(null)
   const navigate = useNavigate();
   const minSwipeDistance = 50 
   localStorage.removeItem("number")
   localStorage.removeItem("userid")
   useEffect(() => {
    setShowSideBar(false)
    setIsSideBarOpen(false)
    setOnPay(false)
    setPaymentDialogOpen(false)
    setIsPaymentDialogOpen(false)
    setQrImage('')
    const location=JSON.parse(localStorage.getItem('location'))
    if(!location)
    navigate('/location');
  }, []);

   const onStartClick=()=>{
    setStartAnimation(true)
   }
   const transitionProperties=startAnimation?{marginTop:'5px',opacity:1}:{}
   const transitionFontProperties=startAnimation?{marginTop:'10px',fontSize:'80px'}:{}
   const transitionContainerProperties=startAnimation?{paddingTop:'100px'}:{}
   const transitionLinkProperties=startAnimation?{display:'none'}:{}
   const transitionLogoProperties=startAnimation?{width:'20vh'}:{}

   const onTouchStart = (e) => {
    setTouchEnd(null) 
    setTouchStart(e.targetTouches[0].clientY)
  }
  
  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY)
  
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'up' : 'down')
    // add your conditional logic here
    setStartAnimation(true)
  }
  const onClickSettings=()=>{
    navigate('/signIn')

  }


    return(
        
            <Container className={classes.container} fluid style={transitionContainerProperties} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                 <div className={classes.logo} >
                    <img  className={classes.logoImage} style={transitionLogoProperties} src={logo}/>

                </div>
                <div className={classes.titleContainer} style={transitionFontProperties}>
                    <h1>FIELDS MANAGER</h1>
                </div>
               
                <div className={classes.phoneNumber} style={transitionProperties} >
                    <PhoneNumberInput/>
                </div>  

                <div  className={`${classes.arrow} ${classes.arrowFirst}`} style={transitionLinkProperties}></div>
                <div className={`${classes.arrow} ${classes.arrowSecond}`} style={transitionLinkProperties}></div>
                <div className={classes.settingscontainer} onClick={onClickSettings}>
                <img  className={classes.settingsicon}  src={settings}/>
                </div>
                 
            </Container>
            
        
    )
}

export default InitialPage;