import { useState,useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Offcanvas from 'react-bootstrap/Offcanvas';
import classes from './Practice.module.css'
import practiceImage from '../../assets/practice-01.png'
import PaymentDetails from '../../components/PaymentDetails/PaymentDetails';
import {getPracticeDetails} from '../../util/api'
import {useLoaderData} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import IdleTimer from '../../components/IdleTimer/IdleTimer';
import { userName } from '../../util/context';
import axios from "axios";
import Logo from '../../assets/logo.png'



function Practice() {
  const [show, setShow] = useState(false);
  const [gameDate, setGameDate] = useState('');
  const [starTime, setStartTime] = useState('');
  const [finisTime, setFinisTime] = useState('');
  const [hours, setHours] = useState('');
  const [startTimeSeconds, setStartTimeSeconds] = useState('');
  const [finishTimeSeconds, setFinishTimeSeconds] = useState('');
  const [price, setPrice] = useState('');
  const [timeOptions, setTimeOptions] = useState('');
  const [timeIsValid, setTimeIsValid] = useState(true);
  const [isToday, setIsToday] = useState(false);
  const [isSlotAvailable, setIsSlotAvailable] = useState(true);
  const [selectedGround, setSelectedGround] = useState();

  const {showSideBar,setShowSideBar,setIsSideBarOpen,setSelectedFormat,setSelectedGroundContext,grounds,setConfirmationDetails,practicePrice}=useContext(userName)




  
  // const practicePrice = useLoaderData();

  
  const onDateSelect=(e)=>{
    console.log(practicePrice)
    setGameDate(e.target.value)
    setTimeOptions ([['12am', getCurrentTime(new Date())]])
    let selectedDate=new Date(e.target.value)
    let todaysDate = new Date();
    if(selectedDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
    setIsToday(true)   
    }
    else{
      setIsToday(false)
    }
}
  function getCurrentTime(date) {
    var hours = date.getHours(),
        minutes = date.getMinutes(),
        ampm = hours >= 12 ? 'pm' : 'am';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;

  return hours + ':' + minutes + ' ' + ampm;
} 

  const checkRange=(seconds)=>{
    let todaysDate=new Date()
    let todaysHours=todaysDate.getHours()
    let todaysMinutes=todaysDate.getMinutes()
    
    if((seconds/60)<(todaysHours*60+todaysMinutes+30)){
      console.log('inside range check '+(todaysHours*60+todaysMinutes))
      setTimeIsValid(false)
    }
    else{
      setTimeIsValid(true)
    }

  }

  const onStartTimeSelect=(e)=>{
    setStartTime(e.target.value)
    let finishTimeHours = finisTime.split(':');
    let startTimeHours=e.target.value.split(':');
    let seconds1 = (startTimeHours[0]*60+parseInt( startTimeHours[1]))*60
    let seconds2 = (finishTimeHours[0]*60+parseInt(finishTimeHours[1]))*60
    setStartTimeSeconds(seconds1)
    setFinishTimeSeconds(seconds2)
    console.log(seconds1)
    let res = Math.abs(seconds2 - seconds1);
    setPrice((res*practicePrice/3600).toFixed(2))
    let hours = Math.floor(res / 3600);
    let minutes = Math.floor(res % 3600 / 60);
    setHours(hours+':'+('0'+minutes).slice(-2))
    checkRange(seconds1)

  }

  const onFinishTimeSelect=(e)=>{
    setFinisTime(e.target.value)
    let finishTimeHours = e.target.value.split(':');
    let startTimeHours=starTime.split(':');
    let seconds1 = (startTimeHours[0]*60+parseInt( startTimeHours[1]))*60
    let seconds2 = (finishTimeHours[0]*60+parseInt(finishTimeHours[1]))*60
    setStartTimeSeconds(seconds1)
    setFinishTimeSeconds(seconds2)
    console.log(seconds1)
    let res = Math.abs(seconds2 - seconds1);
    setPrice((res*practicePrice/3600).toFixed(2))
    let hours = Math.floor(res / 3600);
    let minutes = Math.floor(res % 3600 / 60);
    setHours(hours+':'+('0'+minutes).slice(-2))
  }


  const handleClose = () => {
    setIsSideBarOpen(false)
    setShowSideBar(false)
  };
  const handleShow = () => {

    // axios.post(slotCheck+'/checkOverlap',null,
    // {
        
    //     params: {
    //         Ground_Name: JSON.parse(localStorage.getItem('location')),
    //         Slot:starTime+' - '+finisTime,
    //         Date:gameDate,

    //     }
    //   },)
    // .then((res) => {
    //   if(res.data.message=='No overlap'){
    //     setSelectedFormat('practice')
    //     setIsSideBarOpen(true)
    //     setShowSideBar(true)
    //     setIsSlotAvailable(true)

    //   }
    //   if(res.data.message=='overlap'){
    //     setIsSlotAvailable(false)
    //   }

    // })
    setConfirmationDetails({
      gameDate:gameDate,
      startTime:starTime,
      finishTime:finisTime,
      hours:hours,
      price:price,
      ground:selectedGround

    })
    setSelectedFormat('practice')
    setIsSideBarOpen(true)
    setShowSideBar(true)

  };
  const onChangeGround=(e)=>{
    console.log(e.target.value)
    setSelectedGround(e.target.value)
    setSelectedGroundContext(e.target.value)
  
  }
    return (
      <Container fluid className={classes.container}>
      <Row >
        <Col md={5} className={classes.left} style={{padding:'0px'}}>
            <div >
                <div className={classes.wraper}>
                <div style={{marginRight:'15px'}}>
                    <img src={Logo} />
                    </div>
                    <div style={{textAlign:'center'}} className={classes.title}>
                        <h1>Practice</h1>
                    </div>
                </div>
            
                <div className={classes.imagewraper}>
                    <img src={practiceImage} />
                </div>
                
            </div>
        </Col>
        <Col md={7} style={{padding:'0px'}}>
          <div className={classes.right}>
            <div className={classes.rightwrapper}>
                     
                  <div className={classes.formRow}>
                   {startTimeSeconds>finishTimeSeconds&&<p className={classes.validation}>Finish time must be greater than start time</p>}
                  </div> 
                  <div className={classes.formRow}>
                   {(isToday&&!timeIsValid)&&<p className={classes.validation}>Time is not valid</p>}
                  </div>
                  <div className={classes.formRow}>
                    {(!isSlotAvailable)&&<p className={classes.validation}>Slot is not available</p>}
                  </div>  
                  <div className={classes.formRow}>
                       <span className={classes.label}>Ground</span> 
                       <div className={classes.rightBox}>
                       <select onChange={onChangeGround} value={selectedGround}>
                       <option >Select Ground</option>
                           {grounds.map(ground=>{
                          return( <option value={ground.Ground_Name}>{ground.Ground_Name}</option>)
                          })}
                       </select>
                       </div>
                  </div> 
                  <div className={classes.formRow}>
                    <span className={classes.label}>Date</span> <div className={classes.rightBox}><Form.Control onChange={onDateSelect} type='date' min={new Date().toISOString().split('T')[0]}/></div>
                  </div>
                  <div className={classes.formRow}>
                    <span className={classes.label}>Start At</span> <div className={classes.rightBox}><Form.Control type='time'  onChange={onStartTimeSelect}/></div>
                  </div>
                  <div className={classes.formRow}>
                    <span className={classes.label}>Finish At</span> <div className={classes.rightBox}><Form.Control type='time' onChange={onFinishTimeSelect}/></div>
                  </div>
                  
                  <div className={classes.formRow}>
                   {(starTime&&finisTime&&startTimeSeconds<finishTimeSeconds)?<span className={classes.price}>${price}</span>:<span className={classes.price}>$0</span>} <div className={classes.rightBox}><Button variant="light" className={classes.reqButton} onClick={handleShow} disabled={!selectedGround||!starTime||!finisTime||!gameDate||startTimeSeconds>finishTimeSeconds||isToday&&!timeIsValid}>Request Live Streaming</Button></div>
                  </div>


            </div> 
            </div>
             
        </Col>
      </Row>
      <Offcanvas placement='end' show={showSideBar} onHide={handleClose}>
        
          <PaymentDetails gameDate={gameDate} startTime={starTime} finishTime={finisTime} hours={hours} price={price}/>
       
      </Offcanvas>
      <IdleTimer/>
     
    </Container>
    );
  }
  
  export default Practice;
  // export async function loader() {
  //   return getPracticeDetails();
  // }