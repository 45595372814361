import Container from 'react-bootstrap/Container';
import styles from './SetLocation.module.css'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import logo from '../../assets/logo-initial.png'
import { Button, Typography } from '@mui/material';
import DottedLines from '../../components/DottedLines/DottedLines';


function SetLocation() {
    const navigate = useNavigate();
    const [location, setLocation] = useState('')

    const onClickSetLocation = () => {
        localStorage.setItem('location', JSON.stringify(location));
        navigate('/');
    }

    const onChangeLocation = (e) => {
        setLocation(e.target.value)

    }
    const onClickSignIn = () => {
        navigate('/signIn')
    }

    return (
        <div className={styles.container}>
           <DottedLines/>
            <img
                src={logo}
                alt="Logo"
                className={styles.logo}
            />
            <p className={styles.title}>FIELDS MANAGER</p>
            <p className={styles.subtitle}>Device not configured</p>
            <p className={styles.text}>
                If you are an admin, please sign in to configure your device.
            </p>
            <button className={styles.button} onClick={onClickSignIn}>Sign In</button>
        </div>




    )
}

export default SetLocation;