import React,{useState,useEffect,useContext} from 'react'
import { useNavigate,useLoaderData} from "react-router-dom";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Modal } from 'react-bootstrap';
import classes from './PaymentDetails.module.css'
import {getRequestDetails} from '../../util/api'
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../../assets/spin.gif'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'react-bootstrap/Button';
import { userName } from '../../util/context';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Grid } from '@mui/material';

function PaymentDetails(props) {
   const slotUrl = process.env.REACT_APP_URL_KIOSK;
   const configUrl= process.env.REACT_APP_URL_FM;
   const [isLoading,setIsLoading]=useState(false)
   const [slotNotAvailable,setSlotNotAvailable]=useState(false)
   const [isOverlap,setIsOverlap]=useState(false)
   const [kioskOverlap,setKioskOverlap]=useState(false)
   const [overlapSlot,setOvelapSlot]=useState({})


   const [user,setUser]=useState({})
   const [overlapSlotId,setOverlapSlotId]=useState('')
   const [invoiceDetails,setInvoiceDetails]=useState({
    InvoiceId:null,
    Amount_Paid:null,
    InvoiceCreate_Time:null
   })

   const {setOnPay,setIsSideBarOpen,setShowSideBar,selectedFormat,stopChecking,setServiceRequestId,setSlotId,selectedGround,paymentDialogOpen,setPaymentDialogOpen,setIsPaymentDialogOpen,qrImage,setQrImage,remainingTime}=useContext(userName)


//    const [remainingTime, setRemainingTime] = useState(null);
   const [open, setOpen] = React.useState(false);
 
//    var image = '';

   const navigate = useNavigate();

    var invoice_id = '';
    var checkingForPaymentCompletion = false;
    var intervalId

    const onPay=(overlap)=>{
        console.log(invoiceDetails)
        setIsLoading(true)
        setOnPay(true)
       
        axios.post(slotUrl+'/slots',null,
        
        {
            
            params: {
                Ground_Name: selectedGround,
                Slot: props.startTime+' - '+props.finishTime,
                Date: props.gameDate,
                Bill_Amount: props.price,
                Slot_Type: selectedFormat,
                Email: user.email,
                phoneNumber:JSON.parse(localStorage.getItem('number')),
                User_Id:JSON.parse(localStorage.getItem('userid')),
                invoiceDetails:JSON.stringify(invoiceDetails),
                kioskId:JSON.parse(localStorage.getItem('Kiosk_Id')),
                overlap:overlap,
                Slot_Id:overlapSlotId

            }
          },)
        .then((res) => {
          
          
          if(res.data.httpStatus===200){
              //live streaming
            setServiceRequestId(res.data.Body.Service_Request_Id)
            setSlotId(res.data.Body.Slot_Id)
             
              axios
              .post(slotUrl+'/bid',null,
              {
                  params: {
                      ServiceProvider_Id: res.data.Body.ServiceProvider_Id,
                      Service_Request_Id: res.data.Body.Service_Request_Id,
                      User_Id: res.data.Body.User_Id,
                      Slot_Id: res.data.Body.Slot_Id,
                      Channel_ID: res.data.Body.Channel_ID,
                      Stream_Key: res.data.Body.Stream_Key,
                      Channel_Stream_Url:res.data.Body.Channel_Stream_Url,
                      Service_Id:res.data.Body.Service_Id,
                      Bid_Amount:res.data.Body.Bid_Amount,
                      Comments:res.data.Body.Comments,
                      Email: user.email,
                      Slot: props.startTime+' - '+props.finishTime,
                      Date: props.gameDate,
                      Username:user.first_name


                  }
                },)
              .then((res) => {
                 
                if(res.data.httpStatus===200){
                 console.log('live success')
                 setOnPay(false)
                 setIsSideBarOpen(false)
                 setShowSideBar(false)
                 navigate('/thankyou')
                // navigate('/thankyou')
             }

              })
               //end of live streaming
              //update config file

              console.log('body '+res.data.Body)
              console.log('ServiceProvider_Id '+res.data.Body.ServiceProvider_Id)

              axios
              .post(configUrl+'/updateConfigFile',null,
              {
                  params: {
                      ServiceProvider_Id: res.data.Body.ServiceProvider_Id,
                      Service_Request_Id: res.data.Body.Service_Request_Id,
                      User_Id: res.data.Body.User_Id,
                      Slot_Id: res.data.Body.Slot_Id,
                      Channel_ID: res.data.Body.Channel_ID,
                      Stream_Key: res.data.Body.Stream_Key,
                      Channel_Stream_Url:res.data.Body.Channel_Stream_Url,
                      Service_Id:res.data.Body.Service_Id,
                  }
                },)
              .then((res) => {
                checkingForPaymentCompletion = false;
                 
                 if(res.data==='success'){
                    console.log('config success')
               
                 }

              })
            //end update config
          }
          else{
            console.log('fail')
          }
        });

    }
    
    const notAvailaleModalClose=()=>{
        setSlotNotAvailable(false)
    }
    const paymentDilaogClose=()=>{
        // setPaymentDialogOpen(false)
        return;
    }
    const onEmailChange=(e)=>{
        console.log(e);
        setUser({ ...user, email : e.target.value})
    }
    const onFirstNameChange=(e)=>{
        setUser({ ...user, first_name : e.target.value})
    }
    const onLastNameChange=(e)=>{
        setUser({ ...user, last_name : e.target.value})
    }
    const checkSlotAvailable=()=>{
        axios.post(slotUrl+'/slots/check',null,
        {
            
            params: {
                Ground_Name: selectedGround,
                Slot:props.startTime+' - '+props.finishTime,
                Date:props.gameDate,
                User_Id:JSON.parse(localStorage.getItem('userid')),
                fromkiosk: true,
    
            }
          },)
        .then((res) => {
          if(res.data.message=='No overlap'){
            setOverlapSlotId(res.data.Slot_Id)
           createSlot()
    
          }
          if(res.data.message=='overlap'){
            setIsLoading(false)
            setIsOverlap(true)
            setSlotNotAvailable(true)
            // setConfigData(res.data.configfileJson)
            setOverlapSlotId(res.data.slotData.Slot_Id)
            setOvelapSlot(res.data.slotData)
            
          }
          if(res.data.message=='overlap in kiosk'){
            setIsLoading(false)
            setIsOverlap(true)
            setSlotNotAvailable(true)
            setKioskOverlap(true)
            // setConfigData(res.data.configfileJson)
            setOverlapSlotId(res.data.Slot_Id)
            
          }
    
        })
    }

    const onFormSubmit=()=>{
        setIsLoading(true)
        checkSlotAvailable()
      // navigate('/thankyou')

    }
    const createSlot = () => {
        // setPaymentDialogOpen(true);
        setIsLoading(true)
        axios.post(slotUrl+'/generate-invoice', {
            user : {
                first_name : user.first_name,
                last_name : user.last_name,
                email : user.email,
                phone : JSON.parse(localStorage.getItem('number')).replace(/\D/g, '').slice(-10)
            },
            amount : props.price,
            Bill_Amount: props.price,
            Date: props.gameDate,
            Email: user.email,
            Ground_Name: selectedGround,
            Slot:  props.startTime+' - '+props.finishTime,
            Slot_Id: overlapSlotId,
            Slot_Type: selectedFormat,
            User_Id: JSON.parse(localStorage.getItem('userid')),
            kioskId: JSON.parse(localStorage.getItem('Kiosk_Id')),
            overlap: isOverlap,
            phoneNumber:JSON.parse(localStorage.getItem('number')),
            Username:user.first_name
        }).then((res) => {
            if(res.data.httpStatus===200){
                console.log(res);
                checkingForPaymentCompletion = true;
                invoice_id = res.data.body.invoice_id;
                setQrImage(res.data.body.qrcode)
                setPaymentDialogOpen(true);
                setIsPaymentDialogOpen(true)
                pollUntilConditionIsMet();
            }
        });
    }



    function pollUntilConditionIsMet() {
        const timeoutId = setTimeout(() => {
            console.log('Timeout reached, stopping polling');
            clearInterval(intervalId);
          }, 3 * 60 * 1000); // 2 minutes in milliseconds

          const intervalId = setInterval(() => {
            axios.post(slotUrl+'/invoice-details', { invoice_id })
            .then((res) => {
              if (res.data.body.status == "PAID") {
                console.log('Condition is met!');
                setPaymentDialogOpen(false);
                setIsPaymentDialogOpen(false)
                clearTimeout(timeoutId);
                clearInterval(intervalId);
                navigate('/thankyou')
                
              }
            })
            .catch(error => {
              console.error('Error fetching data:', error);
            });
        }, 5000);
      }
      
      // Call the function to start polling
      





    const startCheckingForPaymentStatus = async () => {
         checkTimeOut(180000)
        while (checkingForPaymentCompletion) {
            let res = await axios.post( slotUrl+'/invoice-details', { invoice_id } );
            console.log(res.data.body.status);
            if( res.data.body.status == "PAID" ) {
                console.log('id',res.data.body.id)
                console.log('amount',res.data.body.amount.value)
                console.log('time',res.data.body.detail.metadata.create_time)
                setInvoiceDetails ({
                    InvoiceId:res.data.body.id,
                    Amount_Paid:res.data.body.amount.value,
                    InvoiceCreate_Time:res.data.body.detail.metadata.create_time
                  });

               
                if(slotNotAvailable){
                    // onPay(true)

                }
                else{
                    // onPay(false);
                }
                checkingForPaymentCompletion = false;
                setPaymentDialogOpen(false);
                setIsPaymentDialogOpen(false)
                navigate('/thankyou')
            } else {
                await new Promise( (resolve, reject) => {
                    setTimeout( () => {
                        resolve();
                    }, 5000 )
                } )
            }
        }
    }
    const checkTimeOut=async (time)=>{
        await new Promise( (resolve, reject) => {
            setTimeout( () => {
                resolve();
            }, time )
        } )
        checkingForPaymentCompletion = false;
    }

    const onContinueSlotCreation=()=>{
        setIsLoading(true)
        setSlotNotAvailable(false)
         createSlot()
    }
    const onGotoHome=()=>{
        navigate('/')
    }
    // const CreateConfigFile=()=>{
    //     setIsLoading(true)
    //     axios
    //     .post(configUrl+'/updateConfigFile',null,
    //     {
    //         params: {
    //             ServiceProvider_Id: configData.ServiceProvider_Id,
    //             Service_Request_Id: configData.Service_Request_Id,
    //             User_Id: JSON.parse(localStorage.getItem('userid')),
    //             Slot_Id: configData.Slot_Id,
    //             Channel_ID: configData.Channel_ID,
    //             Stream_Key: configData.Stream_Key,
    //             Channel_Stream_Url:configData.Channel_Stream_Url,
    //             Service_Id:configData.Service_Id,
    //         }
    //       },)
    //     .then((res) => {
    //     //   checkingForPaymentCompletion = false;
    //     setIsLoading(false)
    //        if(res.data==='success'){
    //           console.log('config success')
    //           setOnPay(false)
    //           setIsSideBarOpen(false)
    //           setShowSideBar(false)
    //           navigate('/thankyou')
         
    //        }

    //     })

    // }

    // const onContinue=()=>{
    //     navigate('/home')
    //     //setSlotNotAvailable(false)
    // }

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
      }
    return (
        <div className={classes.modalContainer}>
            <div className={classes.title}>
                <h1>
                    Payment Details
                </h1>
            </div>
            <div className={classes.rightwrapperContainer}>
                <div className={classes.rightwrapper}>
                    <div className={classes.formRow}>
                        <span className={classes.label}>Hours</span> <div className={classes.rightBox}><span className={classes.label}>{props.hours}</span></div>
                    </div>
                    <div className={classes.formRow}>
                        <span className={classes.label}>Date</span> <div className={classes.rightBox}><span className={classes.label}>{props.gameDate}</span></div>
                    </div>
                    <div className={classes.formRow}>
                        <span className={classes.label}>Start At</span> <div className={classes.rightBox}><span className={classes.label}>{props.startTime}</span></div>
                    </div>
                    <div className={classes.formRow}>
                        <span className={classes.label}>Finish At</span> <div className={classes.rightBox}><span className={classes.label}>{props.finishTime}</span></div>
                    </div>
                    <div className={classes.formRow}>
                        <span className={classes.label}>Total</span> <div className={classes.rightBox}> <span className={classes.price}>${props.price}</span> </div>
                    </div>
                </div> 
            </div>
            <div className={classes.formContainer}>
                <div className={classes.form}>
                    {/* <Form onSubmit={onFormSubmit}> */}
                        <FloatingLabel
                            label="First Name"
                            className="mb-3"
                            onChange={onFirstNameChange}>
                            <Form.Control type="text" placeholder="John" />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Last Name"
                            className="mb-3"
                            onChange={onLastNameChange}>
                            <Form.Control type="text" placeholder="Doe" />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Email"
                            className="mb-3"
                            onChange={onEmailChange}>
                            <Form.Control type="email" placeholder="name@example.com" />
                        </FloatingLabel>
                        <button disabled={!user.first_name||!user.last_name||!user.email} onClick={onFormSubmit} variant="light" className={classes.reqButton} >
                            Checkout
                        </button>
                    {/* </Form> */}
                </div>
            </div> 
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}>
                <img   src={logo} alt="img" width={80} height={80}/>
            </Backdrop>

            {/* <Dialog
                open={slotNotAvailable}
                onClose={notAvailaleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div>
                <DialogContent>
                {!kioskOverlap&&<DialogContentText id="alert-dialog-description" style={{fontSize:'22px'}}>
                The slot is booked by {overlapSlot.Username} at  {overlapSlot.Slot}.Do you still want to go ahead with Livestreaming
                </DialogContentText>}
                {kioskOverlap&&<DialogContentText id="alert-dialog-description" style={{fontSize:'22px'}}>
                  Already requested live streaming
                </DialogContentText>}
                </DialogContent>
                {!kioskOverlap&&<DialogActions style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
                <Button  style={{backgroundColor:'#0E4626',color:'#ffffff'}} onClick={onContinueSlotCreation}>Yes</Button>    
                <Button  style={{backgroundColor:'#0E4626',color:'#ffffff'}} onClick={notAvailaleModalClose}>No</Button>    
                </DialogActions>}
                {kioskOverlap&&<DialogActions style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
                <Button  style={{backgroundColor:'#0E4626',color:'#ffffff'}} onClick={onGotoHome}>Home</Button>        
                </DialogActions>}
                </div>
            </Dialog> */}


            <Modal  centered show={slotNotAvailable}  onHide={notAvailaleModalClose}>
                
                <Modal.Body  className="d-flex flex-column align-items-center">
                
                {!kioskOverlap&&<div id="alert-dialog-description" style={{fontSize:'22px'}}>
                The slot is booked by {overlapSlot.Username} at  {overlapSlot.Slot}.Do you still want to go ahead with Livestreaming
                </div>}
                {kioskOverlap&&<div id="alert-dialog-description" style={{fontSize:'22px'}}>
                  Already requested live streaming
                </div>}

                {!kioskOverlap&&<div style={{textAlign:'center',display:'flex',justifyContent:'center',marginTop:'20px'}}>
                <Button className={classes.noslotbutton} style={{marginRight:'10px'}} onClick={onContinueSlotCreation}>Yes</Button>    
                <Button className={classes.noslotbutton}  onClick={notAvailaleModalClose}>No</Button>    
                </div>}

                {kioskOverlap&&<div style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
                <Button  className={classes.noslotbutton}  onClick={onGotoHome}>Home</Button>        
                </div>}
                  
                </Modal.Body>
              </Modal>






            <Modal  centered show={paymentDialogOpen} >
                
        <Modal.Body  className="d-flex flex-column align-items-center">
        Time to complete your order: {formatTime(remainingTime-10)}

        <div item xs={12} style={{textAlign : 'center'}}>
                                    <img width='150' height='150' src={`data:image/png;base64, ${qrImage}`}/>
                                </div>

                                <div item xs={12} style={{textAlign : 'center'}}>
                                    A payment link has been sent your email or you can scan the QR code from your mobile device. Please complete the payment.
                                </div>
          
        </Modal.Body>
      </Modal>

            {/* <Dialog
                open={paymentDialogOpen}
                onClose={paymentDilaogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEscapeKeyDown={true}>
                
                    <DialogContent>
                    pay in {remainingTime}
                        <DialogContentText id="alert-dialog-description" className={classes.dialogContent}>
                            <Grid>
                            <Grid item xs={12} style={{textAlign : 'center'}}>
                                    pay in {remainingTime}
                                </Grid>
                                <Grid item xs={12} style={{textAlign : 'center'}}>
                                    <img width='150' height='150' src={`data:image/png;base64, ${qrImage}`}/>
                                </Grid>
                                <Grid item xs={12} style={{textAlign : 'center'}}>
                                    A payment link has been sent your email or you can scan the QR code from your mobile device. Please complete the payment.
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                
            </Dialog> */}
        </div>
    )
}
export default PaymentDetails;
