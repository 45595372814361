import {useContext } from 'react';
import Container from 'react-bootstrap/Container';
import styles from './ThankYou.module.css'
import logo from '../../assets/logo-thankyou.png'
import {useNavigate} from 'react-router-dom';
import { userName } from '../../util/context';
import { Button } from 'react-bootstrap';
import axios from "axios";
import DottedLines from '../../components/DottedLines/DottedLines';



function Practice() {
   const slotUrl = process.env.REACT_APP_URL_KIOSK;

    const navigate = useNavigate();
  const {confirmationDetails,selectedGround,slotId,serviceRequestId}=useContext(userName)

    const onGoHomeClick=()=>{
        navigate('/');

    }
    const onSendNotification=(type)=>{
        console.log(type)
        axios
        .post(slotUrl+'/send',null,
        {
            params: {
                SlotId: slotId,
                UserId:JSON.parse(localStorage.getItem('userid')),
                Ground_Name:selectedGround,
                method:type

            }
          },)
        .then((res) => {
            navigate('/final')
        })

    }
    return(
        
            // <div className={classes.container}>
            //     <div className={classes.logo}>
            //         <img src={logo}/>

            //     </div>

            //     {/* <div className={classes.titleContainer}>
            //         <h1>Thank you for choosing us</h1>
            //     </div>
            //     <div className={classes.subTitleContainer}>
            //         <h1>We wish you a good game</h1>
            //     </div> */}

            //     {/* <div className={classes.title}>
            //     <h1>
            //         Payment Details
            //     </h1>
            // </div> */}
            // <div className={classes.rightwrapperContainer}>
            //     <div className={classes.rightwrapper}>
            //     {/* <div className={classes.formRow}>
            //             <span className={classes.label}>Service Request Id</span> <div className={classes.rightBox}><span className={classes.label}>{serviceRequestId}</span></div>
            //         </div> */}
            //         <div className={classes.formRow}>
            //             <span className={classes.label}>Hours</span> <div className={classes.rightBox}><span className={classes.label}>{confirmationDetails.hours}</span></div>
            //         </div>
            //         <div className={classes.formRow}>
            //             <span className={classes.label}>Ground</span> <div className={classes.rightBox}><span className={classes.label}>{confirmationDetails.ground}</span></div>
            //         </div>
            //         <div className={classes.formRow}>
            //             <span className={classes.label}>Date</span> <div className={classes.rightBox}><span className={classes.label}>{confirmationDetails.gameDate}</span></div>
            //         </div>
            //         <div className={classes.formRow}>
            //             <span className={classes.label}>Start At</span> <div className={classes.rightBox}><span className={classes.label}>{confirmationDetails.startTime}</span></div>
            //         </div>
            //         <div className={classes.formRow}>
            //             <span className={classes.label}>Finish At</span> <div className={classes.rightBox}><span className={classes.label}>{confirmationDetails.finishTime}</span></div>
            //         </div>
            //         <div className={classes.formRow}>
            //             <span className={classes.label}>Paid</span> <div className={classes.rightBox}> <span className={classes.price}>${confirmationDetails.price}</span> </div>
            //         </div>
            //         {/* <div className={classes.formRow}>
            //             <Button onClick={()=>onSendNotification('EMAIL')} style={{backgroundColor:'#0E4626',border:'#fff'}}>Send as email</Button > <Button style={{backgroundColor:'#0E4626',border:'#fff'}} onClick={()=>onSendNotification('SMS')}>Send as SMS</Button>
            //         </div> */}
            //         <div className={classes.formRow}>
            //             <span className={classes.label}>For further queries, feel free to contact +19729728393</span>
            //             </div>
            //     </div> 
            // </div>
         

               
            //     <div className={classes.linkToHome} onClick={onGoHomeClick}>
            //         <p>Go Home</p>
            //     </div>                 
                 

            // </div>

            <div className={styles.mycomponent}>
     <DottedLines/>
      <div className={styles.centerbox}>
        <div className={styles.leftBox}>
          <img
            className={styles.logo}
            src={logo}
            alt="Logo"
          />
          <p className={styles.title}>FIELDS MANAGER</p>
        </div>
        <div className={styles.rightbox}>
          <h2 className={styles.rightboxtitle}>Congratulations!</h2>

          <div className={styles.rightwrapper}>
                <div className={styles.formRow}>
                        <span className={styles.label}>Hours</span> <div className={styles.rightBox}><span className={styles.label}>{confirmationDetails.hours}</span></div>
                     </div>
                     <div className={styles.formRow}>
                         <span className={styles.label}>Ground</span> <div className={styles.rightBox}><span className={styles.label}>{confirmationDetails.ground}</span></div>
                     </div>
                    <div className={styles.formRow}>
                         <span className={styles.label}>Date</span> <div className={styles.rightBox}><span className={styles.label}>{confirmationDetails.gameDate}</span></div>
                     </div>
                     <div className={styles.formRow}>
                         <span className={styles.label}>Start At</span> <div className={styles.rightBox}><span className={styles.label}>{confirmationDetails.startTime}</span></div>
                     </div>

                     <div className={styles.formRow}>
                         <span className={styles.label}>Finish At</span> <div className={styles.rightBox}><span className={styles.label}>{confirmationDetails.finishTime}</span></div>
                     </div> 
                     <div className={styles.formRow}>
                         <span className={styles.label}>Paid</span> <div className={styles.rightBox}><span className={styles.label}>${confirmationDetails.price}</span></div>
                     </div>

                    
                  
                     {/* <div className={styles.buttoncontainer}>
                         <Button className={styles.changebutton} onClick={()=>onButtonClick('parklist')} >Change</Button> <Button className={styles.homebutton} onClick={()=>onButtonClick('home')} >Go To Home</Button >
                    </div> */}
                    
                   
                 </div> 
                 <h2 className={styles.thakyou_message}>Thank you for choosing us</h2>
                 <h2 className={styles.thakyou_message_sub}>We wish you a good game</h2>
                 <Button className={styles.homebutton} onClick={onGoHomeClick} >Go To Home</Button >

        
        </div>
      </div>
      

    </div>
            
        
    )
}

export default Practice;