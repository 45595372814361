import * as React from 'react';
import Container from 'react-bootstrap/Container';
import styles from './ParkList.module.css'
import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import logo from '../../assets/logo-initial.png'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import { userName } from '../../util/context';
import axios from "axios";
import userPool from '../../userPool';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DottedLines from '../../components/DottedLines/DottedLines';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel, RadioGroup,

} from "@mui/material";


function ParkList() {

  const slotUrl = process.env.REACT_APP_URL_KIOSK;

  const [selectedParkId, setselectedParkId] = useState({});
  const { setSelectedPark, allParks, cityId, setAllParks, sportsList } = useContext(userName)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [parkName, setParkName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [website, setWebsite] = useState("");
  const [showGroundForm, setShowGroundForm] = useState(false);

  const [groundName, setGroundName] = useState('');
  const [groundType, setGroundType] = useState('');
  const [indoor, setIndoor] = useState(false);
  const [sports, setSports] = useState([]);
  const [selectedOption, setSelectedOption] = useState('indoor');
  const [newParkId, setNewParkId] = useState('');


  const handleSportsChange = (event) => {
    setSports(event.target.value);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault();
    if (!showGroundForm) {
      setOpen(false);
      createPark()

    }
    else {
      setOpen(false);
      getSession().then(session => {
        createGround(session.getIdToken().getJwtToken())
      })

    }
  };

  const createPark = () => {
    axios
      .post(slotUrl + '/admin/parksandgrounds', null,
        {
          params: {
            "Agreement": false,
            "Game_Formats": '1,2,3',
            "Kiosk_Status": "Active",
            "city": city,
            "cityId": cityId,
            "parkName": parkName,
            "state": state,
            "street": street,
            "website": website,
            "zipCode": zipCode,


          }
        },)
      .then((res) => {
        console.log(res)
        if (res.data) {
          getSession().then(session => {
            getParks(session.getIdToken().getJwtToken())
          })
          setOpen(true);
          setShowGroundForm(true)
          setNewParkId(res.data.park.parkId)

        }

      })
    console.log(parkName, street, city, state, zipCode, website);

  }

  const createGround = (idToken) => {

    const modifiedSport = sports.map(sport => {
      return {
        _sportId: sport.Sport_Id,
        _sportName: sport.Sport_Name,
        _checked: true,
        Custom_Price: 0
      }
    })
    console.log(groundName, selectedOption, modifiedSport)
    console.log('parkid', newParkId)

    axios
      .post(slotUrl + '/admin/parksandgrounds', null,
        {
          params: {
            Custom_Price: null,
            groundName: groundName,
            groundType: selectedOption,
            parkId: newParkId,
            cityId: cityId,
            sport: JSON.stringify(modifiedSport),
            idToken: idToken
          }
        })
      .then((res) => {
        handleClose()
        console.log(res)
        if (res.data) {
          setSports([])
          setShowGroundForm(false)

        }
        setIsLoading(false)

      })

  }




  const handleChange = (e) => {
    setselectedParkId(e.target.value)
    console.log(e.target.value)
  }

  const onNext = () => {
    const selPark = allParks.find(park => park.Park_Id == selectedParkId)
    console.log(selPark)
    setSelectedPark(selPark)
    navigate('/configure');
  }

  const getParks = (idToken) => {
    axios
      .get(slotUrl + '/admin/getDetails',
        {
          params: {
            idToken: idToken,
          }
        },)
      .then((res) => {
        console.log(res)
        if (res.data) {
          setAllParks(res.data.Results)

          // handleClose();

        }
        setIsLoading(false)
      })

  }
  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser()
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject()
          }
          else {
            resolve(session)
          }
        })
      } else {
        reject()
      }
    })

  }

  return (
    <>



      <div className={styles.mycomponent}>
       <DottedLines/>
        <div className={styles.centerbox}>
          <div className={styles.leftBox}>
            <img
              className={styles.logo}
              src={logo}
              alt="Logo"
            />
            <p className={styles.title}>FIELDS MANAGER</p>
          </div>
          <div className={styles.rightbox}>
            <h2 className={styles.rightboxtitle}>Select Park</h2>



            <div className={styles.parklist_box} style={{ overflowY: "auto" }}>
              <List sx={{ width: '100%', maxWidth: 360 }}>
                {allParks.map(park => (
                  <div>
                    <ListItem alignItems="center" >

                      <ListItemText
                        primary={park.Park_Name}
                        className={styles.parkname}

                        secondary={
                          <React.Fragment>
                            <Typography
                              className={styles.city}

                              component="span"
                              variant="body2"

                            >
                              {park.Street}
                            </Typography >
                            <span className={styles.street} >{park.City}</span>
                          </React.Fragment>
                        }
                      />

                      <Radio
                        checked={selectedParkId == park.Park_Id}
                        onChange={(e) => handleChange(e)}
                        value={park.Park_Id}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </div>

                ))}
              </List>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '20px',
              width: '25rem'
            }}>
              <Button variant="outlined" className={styles.add_park_button} onClick={handleOpen}>
                Add Park
              </Button>
              <Button variant="contained" className={styles.next_button} onClick={onNext}>Next</Button>
            </div>



          </div>
        </div>

      </div>


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={styles.add_Park_modal}>{showGroundForm ? 'Add Ground' : 'Add Park'}</DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.add_Park_modal_sub}>
            Please enter the details of the {showGroundForm ? 'ground' : 'park'} you want to add.
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            {!showGroundForm &&
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  id="parkName"
                  label="Park Name"
                  type="text"
                  fullWidth
                  value={parkName}
                  onChange={(e) => setParkName(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="street"
                  label="Street"
                  type="text"
                  fullWidth
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="city"
                  label="City"
                  type="text"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="state"
                  label="State"
                  type="text"
                  fullWidth
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="zipCode"
                  label="Zip Code"
                  type="text"
                  fullWidth
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="website"
                  label="Website"
                  type="text"
                  fullWidth
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </>
            }
            {showGroundForm &&
              <>
                <TextField
                  margin="dense"
                  id="groundname"
                  type="text"
                  fullWidth
                  required
                  label="Ground Name"
                  value={groundName}
                  onChange={(event) => setGroundName(event.target.value)}
                />

                <FormGroup row>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Select one option:</FormLabel>
                    <RadioGroup aria-label="option" name="option" value={selectedOption} onChange={handleOptionChange}>
                      <FormControlLabel value="indoor" control={<Radio />} label="Indoor" />
                      <FormControlLabel value="outdoor" control={<Radio />} label="Outdoor" />
                    </RadioGroup>
                  </FormControl>
                </FormGroup>
                <FormControl fullWidth >
                  <InputLabel id="sports-label">Sports</InputLabel>
                  <Select
                    labelId="sports-label"
                    id="sports-select"
                    label="Sports"
                    multiple
                    value={sports}
                    onChange={handleSportsChange}
                    inputProps={{
                      name: 'sports',
                      id: 'sports-checkboxes',
                    }}
                  >
                    {
                      sportsList.map(sport => {
                        return (<MenuItem value={sport}>{sport.Sport_Name}</MenuItem>)
                      })
                    }

                  </Select>
                </FormControl>
              </>
            }
            <div className={styles.add_park_modal_button_container} >
              <Button className={styles.add_park_modal_button_cancel} onClick={handleClose} >
                Cancel
              </Button>
              <Button className={styles.add_park_modal_button_save} type="submit" >
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </>


  )
}

export default ParkList;