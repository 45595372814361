import React,{useState,useEffect,useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import classes from './IdleTimer.module.css'
import { useIdleTimer } from 'react-idle-timer'
import { userName } from '../../util/context';
import { Modal } from 'react-bootstrap';







function IdleTimer() {
    const navigate = useNavigate();
    // const [remainingTime, setRemainingTime] = useState('');
    const [open, setOpen] = React.useState(false);
    const {showSideBar,setShowSideBar,isSideBarOpen,onPay,setStopChecking,setPaymentDialogOpen,isPaymentDialogOpen,remainingTime, setRemainingTime}=useContext(userName)
  
    
    useEffect(() => {
      //  console.log('inside useeffect idle')
      // setRemainingTime(Math.floor(getRemainingTime()/1000))
      const intervalId=setInterval(() => {
        // console.log('test',Math.ceil(getRemainingTime()/1000))
        setRemainingTime(Math.ceil(getRemainingTime()/1000))
        
      }, 1000)
      return () => {
        clearInterval(intervalId);
      };
    },[]);
    const handleClose = () => {
      setOpen(false);
    };
    const handleOnIdle = event => {
      console.log('user is idle', event)
      if(!onPay){
      setShowSideBar(false)
      setPaymentDialogOpen(false)
      setOpen(false)
      setStopChecking(true)
      navigate('/');
      }
    }
  
    const handleOnActive = event => {
     
    }
  
    const handleOnAction = event => {
      
    }
  
    const handleOnPrompt=(event)=>{
      if(isPaymentDialogOpen)
      navigate('/');
      if(!onPay){
      setShowSideBar(false)
      setPaymentDialogOpen(false)
      setOpen(true)
      }
    
  
    }
    const handleReset = () => reset()
    const onContinue=()=>{
      if(isSideBarOpen)
      setShowSideBar(true)
      if(isPaymentDialogOpen)
      setPaymentDialogOpen(true)
      setOpen(false)
      handleReset()
    }
  
    const { getRemainingTime, getLastActiveTime,reset } = useIdleTimer({
      timeout: 1000  * 180,
      promptTimeout:10000,
      onIdle: handleOnIdle,
      onActive: handleOnActive,
      onAction: handleOnAction,
      onPrompt:handleOnPrompt,
      debounce: 500
    })
  

    return(
        
      //   <Dialog
   
      //   open={open}
      //   aria-labelledby="alert-dialog-title"
      //   aria-describedby="alert-dialog-description"
       
      // >
      //   <div  style={{backgroundColor: '#ffffff'}}>
      //   <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} style={{fontSize:'30px'}}>
      //     {"Are you still here?"}
      //   </DialogTitle>
      //   <DialogContent>
      //     <DialogContentText id="alert-dialog-description" className={classes.dialogContent}>
      //     <p>You will be automatically logged out in <span className={classes.dialogNumber}>{remainingTime}</span> seconds...</p>
      //     </DialogContentText>
      //   </DialogContent>
      //   <DialogActions style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
      //     <Button style={{backgroundColor:'#0E4626',color:'#ffffff'}} onClick={onContinue}>Continue</Button>
          
      //   </DialogActions>
      //   </div>
      // </Dialog>

      <Modal  centered show={open} >
                
      <Modal.Body  className="d-flex flex-column align-items-center">
      <div  style={{backgroundColor: '#ffffff'}}>
         <div  className={classes.dialogTitle} style={{fontSize:'30px'}}>
           {"Are you still here?"}
         </div>
         <div>
           <div  className={classes.dialogContent}>
           <p>You will be automatically logged out in <span className={classes.dialogNumber}>{remainingTime}</span> seconds..</p>
           </div>
         </div>
         <div style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
           <Button style={{backgroundColor:'#0E4626',color:'#ffffff'}} onClick={onContinue}>Continue</Button>
          
         </div>
         </div>
      </Modal.Body>
    </Modal>
            
        
    )
}

export default IdleTimer;