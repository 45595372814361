import { useRouteError,useNavigate} from "react-router-dom";
import logo from '../../assets/logo-initial.png'
import { TextField, Button,Box,Typography} from '@mui/material';
import styles from './ErrorPage.module.css'
import DottedLines from "../../components/DottedLines/DottedLines";
import axios from "axios";

export default function ErrorPage() {
  const slotUrl = process.env.REACT_APP_URL_KIOSK;

  const navigate = useNavigate();
  const error = useRouteError();
  console.error(error);
  const onGoHome=()=>{
    console.log('sta',error.statusText)
    console.log('msg',error.message)
    axios
    .post(slotUrl+'/erroralert',
    {
      errormessage: error.message
      },)
    .then((res) => {
      navigate('/')
    })
}
  return (
    // <div id="error-page">
    //   {/* <h1>Oops!</h1>
    //   <p>Sorry, an unexpected error has occurred.</p>
    //   <p>
    //     <i>{error.statusText || error.message}</i>
    //   </p> */}
    //    <div style={{
    //             display: 'flex',
    //           flexDirection: 'column',
    //           alignItems: 'center',
    //         //   justifyContent: 'center',
    //           minHeight: '100vh',
    //           background: '#0E4626'
    
    //         }}>
    //             <div className={classes.logo} >
    //                 <img  className={classes.logoImage}  src={logo}/>

    //             </div>
    //             <Box
    //     sx={{
    //       display: 'flex',
    //       flexDirection: 'column',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //       width:'90vh',
    //       minHeight:'55vh',  
        
    //          }}
    //   >
    //             <Typography gutterBottom style={{ fontSize: '1.6rem',color:'white' }}>
    //             An unexpected error occured!
    //             </Typography>
    //             <Button onClick={onGoHome} variant="contained" style={{backgroundColor:'#FFFFFF',color:'black',marginTop:'12px',marginBottom:'22px'}}>
    //           Go To Home
    //         </Button>
    //         <Typography gutterBottom style={{ fontSize: '1.1rem',color:'white' }}>
    //            If the problem persists, Please contact technical support @ +19729728393 !
    //             </Typography>
    //             </Box>
    //         </div>
    // </div>

    <div className={styles.mycomponent}>
     <DottedLines/>
      <div className={styles.centerbox}>
        <div className={styles.leftBox}>
          <img
            className={styles.logo}
            src={logo}
            alt="Logo"
          />
          <p className={styles.title}>FIELDS MANAGER</p>
        </div>
        <div className={styles.rightbox}>
          <div className={styles.rightboxtitle}>
            <div className={styles.svgcontainer}>
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25 0C11.2 0 0 11.1875 0 25C0 38.8125 11.2 50 25 50C38.8 50 50 38.8125 50 25C50 11.1875 38.8 0 25 0ZM27.5 35.5C27.5 36.6046 26.6046 37.5 25.5 37.5H24.5C23.3954 37.5 22.5 36.6046 22.5 35.5V34.5C22.5 33.3954 23.3954 32.5 24.5 32.5H25.5C26.6046 32.5 27.5 33.3954 27.5 34.5V35.5ZM27.5 25.5C27.5 26.6046 26.6046 27.5 25.5 27.5H24.5C23.3954 27.5 22.5 26.6046 22.5 25.5V14.5C22.5 13.3954 23.3954 12.5 24.5 12.5H25.5C26.6046 12.5 27.5 13.3954 27.5 14.5V25.5Z" fill="#0E4626"/>
</svg>
</div>
<div className={styles.errormessage}>
<p >
                 An unexpected error occured!
                </p>

          </div>
<div className={styles.buttoncontainer}>
          <Button onClick={onGoHome} variant="contained" className={styles.homebutton}>
               Go To Home
             </Button>
             </div>

             <div className={styles.support}>
<p >
if the problem persists,  please contact technical support @ +19729728393
                </p>

          </div>

          </div>     

          
        </div>
      </div>
     

    </div>
  );
}