import React,{ useState,useEffect,useContext} from "react";
import { Outlet,Link,useLoaderData,useNavigate,NavLink} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './Game.module.css'
import Logo from '../../assets/logo.png'
import gameImage from '../../assets/game-01.png'
import IdleTimer from "../../components/IdleTimer/IdleTimer";
import { useIdleTimer } from 'react-idle-timer'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { userName } from '../../util/context';
import RequestForm from "../RequestForm/RequestForm";




function Game() {
  const [src, setSrc] = useState(gameImage);
  const [remainingTime, setRemainingTime] = useState(gameImage);
  const [open, setOpen] = React.useState(false);
  const [gameformat, setGameFormat] = React.useState('');
  const [showForm, setShowForm] = React.useState(false);


  const {gameFormats}=useContext(userName)




  const navigate = useNavigate();
  const onClickFormat=(id)=>{
    let selectedGameFormat = gameFormats.find(format => format.id == id);
    setGameFormat(selectedGameFormat)
    setShowForm(true)
    console.log(selectedGameFormat)
  }
    return (
      <Container fluid className={classes.container}>
      <Row >
        <Col md={5} className={classes.left} style={{padding:'0px'}}>
            <div >
                <div className={classes.wraper}>
                    <div style={{marginRight:'15px'}}>
                    <img src={Logo} />
                    </div>
                    <div style={{textAlign:'center'}} className={classes.title}>
                        <h1>Game</h1>
                    </div>
                </div>
            
                <div className={classes.imagewraper}>
                    <img src={gameImage} />
                </div>
                
            </div>
        </Col>
        <Col md={7} style={{padding:'0px'}}>
            <div className={classes.rightwrapper}>
                <div className={classes.right_title}>
                    <h1>Choose game format</h1>
                </div>
                <div  className={classes.gameBoxWrapper}>
                  {
                    gameFormats.map(format=>{
                      return(<NavLink key={format.id} style={{color: 'inherit',color:'#fff'}} onClick={()=>onClickFormat(format.id)} className={gameformat.id==format.id?classes.gameBox :classes.gameBoxPassive}><h2>{format.format}</h2></NavLink>)
                    })
                  }
                    {/* <NavLink style={{color: 'inherit',color:'#fff'}} to={'request/1'} className={({ isActive, isPending }) =>
                      isActive
                        ? classes.gameBox
                        : isPending
                        ? classes.gameBoxPassive
                        : classes.gameBoxPassive
                    }><h2>T10</h2></NavLink>

                    <NavLink style={{color: 'inherit',color:'#fff'}} to={'request/2'} className={({ isActive, isPending }) =>
                      isActive
                        ? classes.gameBox
                        : isPending
                        ? classes.gameBoxPassive
                        : classes.gameBoxPassive
                    }><h2>T20</h2></NavLink>
                    <NavLink style={{color: 'inherit',color:'#fff'}} to={'request/3'} className={({ isActive, isPending }) =>
                      isActive
                        ? classes.gameBox
                        : isPending
                        ? classes.gameBoxPassive
                        : classes.gameBoxPassive
                    }><h2>50 Overs</h2></NavLink>              */}
                </div>
            
            </div> 
            <div className={classes.formWrapper}>
            {/* <Outlet /> */}
           {showForm&&<RequestForm gameFormat={gameformat}/>}
            </div>         
        </Col>
      </Row>
      <IdleTimer/>
     
    </Container>
    );
  }
  
  export default Game;