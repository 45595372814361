import Container from 'react-bootstrap/Container';
import styles from './SignIn.module.css'
import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import logo from '../../assets/logo-initial.png'
import usericon from '../../assets/user.png'

import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import userPool from '../../userPool';
import { userName } from '../../util/context';
import axios from "axios";
import { TextField, Button, Box, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DottedLines from '../../components/DottedLines/DottedLines';


function SignIn() {
  const slotUrl = process.env.REACT_APP_URL_KIOSK;
  const { setAllParks, setCityId, setSportsList, setAdminUserName, setConfigData } = useContext(userName)
  const { setchannelIds, setRepData } = useContext(userName)


  const navigate = useNavigate();
  const [location, setLocation] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginErro, setLoginError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)




  const onClickSetLocation = () => {
    localStorage.setItem('location', JSON.stringify(location));
    navigate('/');
  }

  const onChangeLocation = (e) => {
    setLocation(e.target.value)

  }
  const handleSubmit = (event) => {
    setAdminUserName(username)
    setIsLoading(true)
    // navigate('/parklist');
    event.preventDefault();
    const user = new CognitoUser({
      Username: username,
      Pool: userPool
    })
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    })

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("on Success ", data.getIdToken().getJwtToken())
        getParks(data.getIdToken().getJwtToken())


      },
      onFailure: (err) => {
        console.log("on Failure ", err)
        setLoginError(true)
        setIsLoading(false)
      },
      newPasswordRequired: (data) => {
        console.log("on newpassword ", data)

      }
    })
  }

  const getParks = (idToken) => {
    const kioskId = JSON.parse(localStorage.getItem('Kiosk_Id'))
    let params
    if (kioskId) {
      params = {
        idToken: idToken,
        Kiosk_Id: JSON.parse(localStorage.getItem('Kiosk_Id'))
      }

    } else {
      params = {
        idToken: idToken,
      }

    }
    axios
      .get(slotUrl + '/admin/getDetails',
        {
          params
        },)
      .then((res) => {
        console.log(res)
        if (res.data) {
          setAllParks(res.data.Results)
          setchannelIds(res.data.channels)
          setCityId(res.data.CityId)
          setSportsList(res.data.sportsData)
          setRepData(res.data.repData)
          if (res.data.setupData) {
            setConfigData(res.data.setupData)
            navigate('/configurationdetails');

          }
          else {
            navigate('/parklist');
          }
          setIsLoading(false)
        }

      })

  }

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser()
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject()
          }
          else {
            resolve(session)
          }
        })
      } else {
        reject()
      }
    })

  }

  const onChangeUserName = (e) => {
    // setUserName(e.target.value)

  }
  const onChangePassword = (e) => {
    // setPassword(e.target.value)

  }

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);

  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const onClickSettings=()=>{
    navigate('/')

  }



  return (


    <div className={styles.mycomponent}>
     <DottedLines/>
      <div className={styles.centerbox}>
        <div className={styles.leftBox}>
          <img
            className={styles.logo}
            src={logo}
            alt="Logo"
          />
          <p className={styles.title}>FIELDS MANAGER</p>
        </div>
        <div className={styles.rightbox}>
          <h2 className={styles.rightboxtitle}>Log In</h2>
          {loginErro && (
            <div className={styles.rightBox__error}>Invalid username or password</div>
          )}
          <form className={styles.right_box__form}>
            <TextField
              label="Username"
              className={styles.username_input}
              variant="outlined"
              value={username}
              onChange={handleUsernameChange}
            />
            <TextField
              label="Password"
              type='password'
              className={styles.password_input}
              variant="outlined"
              value={password}
              onChange={handlePasswordChange}
            />
            <button className={styles.right_box__login_btn} onClick={handleSubmit}>Log In</button>
          </form>
        </div>
      </div>
      <div className={styles.settingscontainer} onClick={onClickSettings}>
                <img  className={styles.settingsicon}  src={usericon}/>
                </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>

  )
}

export default SignIn;