import {useContext } from 'react';
import Container from 'react-bootstrap/Container';
import styles from './ConfigurationDetails.module.css'
import logo from '../../assets/logo-thankyou.png'
import {useNavigate} from 'react-router-dom';
import { userName } from '../../util/context';
import { Button } from 'react-bootstrap';
import axios from "axios";
import userPool from '../../userPool';
import DottedLines from '../../components/DottedLines/DottedLines';


function ConfigurationDetails() {
   const navigate = useNavigate();
    const {confirmationDetails,selectedGround,slotId,serviceRequestId,configData}=useContext(userName)

    const onGoHomeClick=()=>{
        navigate('/');

    }
    const onButtonClick=(type)=>{
        
        if(type=='home'){
          const user = userPool.getCurrentUser()
          if (user) {
            user.signOut()
          }
            navigate('/');
        }
        else{
            navigate('/parklist');
        }

    }
    return(
       

<div className={styles.mycomponent}>
     <DottedLines/>
      <div className={styles.centerbox}>
        <div className={styles.leftBox}>
          <img
            className={styles.logo}
            src={logo}
            alt="Logo"
          />
          <p className={styles.title}>FIELDS MANAGER</p>
        </div>
        <div className={styles.rightbox}>
          <h2 className={styles.rightboxtitle}>Configured!</h2>

          <div className={styles.rightwrapper}>
                <div className={styles.formRow}>
                        <span className={styles.label}>kiosk Id</span> <div className={styles.rightBox}><span className={styles.label}>{configData.Kiosk_Id}</span></div>
                     </div>
                     <div className={styles.formRow}>
                         <span className={styles.label}>Name</span> <div className={styles.rightBox}><span className={styles.label}>{configData.kioskName}</span></div>
                     </div>
                    <div className={styles.formRow}>
                         <span className={styles.label}>Channel Id</span> <div className={styles.rightBox}><span className={styles.label}>{configData.Channel_Id}</span></div>
                     </div>
                     <div className={styles.formRow}>
                         <span className={styles.label}>Park</span> <div className={styles.rightBox}><span className={styles.label}>{configData.Park_Name}</span></div>
                     </div>

                     <div className={styles.buttoncontainer}>
                         <Button className={styles.changebutton} onClick={()=>onButtonClick('parklist')} >Change</Button> <Button className={styles.homebutton} onClick={()=>onButtonClick('home')} >Go To Home</Button >
                    </div>
                    
                   
                 </div> 
        
        </div>
      </div>
      

    </div>
        
        
    )
}

export default ConfigurationDetails;