import React, { useState,useRef,useEffect,useContext} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './Home.module.css'
import gameImage from '../../assets/game.png'
import gameHover from '../../assets/game-hover.png'
import practiceImage from '../../assets/practice.png'
import practiceHover from '../../assets/practice-hover.png'
import {useNavigate} from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { userName } from '../../util/context';
import IdleTimer from '../../components/IdleTimer/IdleTimer';
import logo from '../../assets/logo-thankyou.png'



function Home() {
  const {name}=useContext(userName)
  const [src, setSrc] = useState(gameImage);
  const [remainingTime, setRemainingTime] = useState(gameImage);
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();


  const onGameClick=()=>{
    navigate('/game');
  }

  const onPracticeClick=()=>{
    navigate('/practice')
  }
  return (
    <Container fluid>
    <Row >
      <Col md={4} className={classes.left}>
         <div className={classes.logo}>
        <img src={logo}/>

        </div>
        <div className={classes.title}>
            Request Live Streaming
        </div>
      </Col>
      <Col md={8} style={{padding:'0px'}} className={classes.right}>
        <div className={classes.imageBox}>
          <img src={src} onClick={onGameClick} 
               onMouseOver={()=>setSrc(gameHover)}
               onMouseOut={()=>setSrc(gameImage)} />
          <div className={classes.imageText} onClick={onGameClick}>
            <h1>Game </h1>   
          </div>
        </div> 
        <div className={classes.imageBox}>      
          <img src={practiceImage} />
          <div className={classes.imageText} onClick={onPracticeClick}>
            <h1>Practice</h1>   
          </div>
        </div>  
      </Col>
    </Row>
    <IdleTimer/>
  </Container>
  );
}

export default Home;
